<template>
  <div class="wrapper">
    <div class="body mt-1">
      <Games
        v-bind:sport="sport_id"
        esport="true"
        v-show="current_sub_page !== 'countries'"
      ></Games>

      <BottomNavigation active-bets="0" page="home"></BottomNavigation>
    </div>
  </div>
</template>

<script>
import Games from "./Games";
import BottomNavigation from "./BottomNavigation";

export default {
  name: "Esport",
  components: {
    Games,
    BottomNavigation,
  },
  data: function () {
    return {
      sub_page: "",
    };
  },
  mounted() {
    this.$store.dispatch("setCurrentPage", "esport");
    //this.$store.dispatch("setSportID",1);
    this.$store.dispatch("setHour", -1);
    //this.$store.dispatch("setMarketID",1);
    this.$store.dispatch("setCompetitionID", 0);

    // reset game filter
    //this.$store.dispatch("resetAllGames");
    this.reloadProfile();
  },
  computed: {
    betslip_count: function () {
      return this.$store.state.betslip.total;
    },
    current_sub_page: function () {
      return this.$store.state.current_sub_page;
    },
    sport_id: function () {
      return this.$store.state.sport_id;
    },
  },
  methods: {
    setSubPage: function (page) {
      this.$store.dispatch("setCurrentSubPage", page);
      this.sub_page = page;
    },
    geSubPageActiveClass: function (subpage) {
      return subpage === this.sub_page ? "active" : "";
    },
    setSport: function (sportID) {
      this.$store.dispatch("setSportID", sportID);
    },
  },
};
</script>
